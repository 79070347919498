import axios from "axios";
import { get } from "lodash";
import React from "react";
import { Container } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import VisibilitySensor from "react-visibility-sensor";
import Arrow from "../../assets/img/btn-arrow.svg";
import Navbar from "../../components/navbar/Navbar";
import { API_URL } from "../../utils/constant";
import "./header.scss";
import { Fade } from "react-reveal";

const Header = ({ page, greeting }) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery("homepage", async () => {
    const response = await axios.get(
      `${API_URL}/api/homepage?populate=*&locale=${i18n.language}`
    );
    return response.data.data.attributes;
  });

  return (
    <div className="header">
      <Navbar navNumbers={get(data, "navNubers", [])} />
      <Element name="home" className="" style={{ overflow: "hidden" }}>
        <Container>
          {page === "home" ? (
            <div className="parent-box">
              <div className="numbers-box">
                <Fade left duration={1300}>
                  <div className="count-box align-items-start">
                    <CountUp
                      start={0}
                      end={get(data, "clients", 0)}
                      duration={2}
                      redraw={true}
                      suffix="+"
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <>
                            <div
                              ref={countUpRef}
                              className="number justify-content-start"
                            />
                          </>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <div className="title">{t("Clients")}</div>
                  </div>
                </Fade>
                <div className="desc">
                  {
                    get(data, "greeting", []).find(
                      (item) => item.type === "home"
                    )?.description
                  }
                </div>
                <Fade right duration={1300}>
                  <div className="count-box align-items-end">
                    <CountUp
                      start={0}
                      end={get(data, "services", 0)}
                      duration={2}
                      redraw={true}
                      suffix="+"
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <>
                            <div
                              ref={countUpRef}
                              className="number justify-content-end"
                            />
                          </>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <div className="title">{t("Services")}</div>
                  </div>
                </Fade>
              </div>
              <div className="center-content">
                <Fade top duration={1300}>
                  {
                    get(data, "greeting", [])?.find(
                      (item) => item.type === "home"
                    )?.greeting
                  }
                </Fade>
              </div>
              <Fade bottom duration={1300}>
                <div className="buttons">
                  <button
                    className="circle-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    <img src={Arrow} alt="" />
                  </button>
                  <button
                    className="text-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    {t("Contact")}
                  </button>
                </div>
              </Fade>

              <div className="numbers-box">
                <Fade left duration={1300}>
                  <div className="count-box align-items-start">
                    <CountUp
                      start={0}
                      end={get(data, "products", 0)}
                      duration={2}
                      redraw={true}
                      suffix="+"
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <>
                            <div
                              ref={countUpRef}
                              className="number justify-content-start"
                            />
                          </>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <div className="title">{t("Products")}</div>
                  </div>
                </Fade>

                <Fade right duration={1300}>
                  <div className="count-box align-items-end">
                    <CountUp
                      start={0}
                      end={get(data, "employees", 0)}
                      duration={2}
                      redraw={true}
                      suffix="+"
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <>
                            <div
                              ref={countUpRef}
                              className="number justify-content-end"
                            />
                          </>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <div className="title">{t("Employees")}</div>
                  </div>
                </Fade>
              </div>
            </div>
          ) : page === "services" ? (
            <div className="parent-box">
              <div className="center-content">
                <Fade top duration={1300}>
                  {
                    get(data, "greeting", [])?.find(
                      (item) => item.type === "services"
                    )?.greeting
                  }
                </Fade>
              </div>
              <Fade bottom duration={1300}>
                <div className="buttons">
                  <button
                    className="circle-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    <img src={Arrow} alt="" />
                  </button>
                  <button
                    className="text-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    {t("Contact")}
                  </button>
                </div>
              </Fade>
            </div>
          ) : page === "news" ? (
            <div className="parent-box">
              <div className="center-content">
                <Fade top duration={1300}>
                  {
                    get(data, "greeting", [])?.find(
                      (item) => item.type === "news"
                    )?.greeting
                  }
                </Fade>
              </div>
            </div>
          ) : page === "products" ? (
            <div className="parent-box">
              <div className="center-content">
                <Fade top duration={1300}>
                  {
                    get(data, "greeting", [])?.find(
                      (item) => item.type === "products"
                    )?.greeting
                  }
                </Fade>
              </div>
              <Fade bottom duration={1300}>
                <div className="buttons">
                  <button
                    className="circle-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    <img src={Arrow} alt="" />
                  </button>
                  <button
                    className="text-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    {t("Order")}
                  </button>
                </div>
              </Fade>
            </div>
          ) : page === "product-item" ? (
            <div className="parent-box">
              <div className="center-content">
                <Fade top duration={1300}>
                  {greeting}
                </Fade>
              </div>
              <Fade bottom duration={1300}>
                <div className="buttons">
                  <button
                    className="circle-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    <img src={Arrow} alt="" />
                  </button>
                  <button
                    className="text-btn"
                    onClick={() => navigate("/contacts")}
                  >
                    {t("Order")}
                  </button>
                </div>
              </Fade>
            </div>
          ) : (
            ""
          )}
        </Container>
      </Element>
    </div>
  );
};

export default Header;
