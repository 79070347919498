import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Loading from "./components/loading/Loading";
import HomePage from "./pages/HomePage";
import { useEffect } from "react";

const ProductsPage = React.lazy(() => import("./pages/ProductsPage"));
const ProductItemPage = React.lazy(() => import("./pages/ProjectItemPage"));
const ServicesPage = React.lazy(() => import("./pages/ServicesPage"));
const NewsPage = React.lazy(() => import("./pages/NewsPage"));
const NewsItemPage = React.lazy(() => import("./pages/NewsItemPage"));
const ContactsPage = React.lazy(() => import("./pages/ContactsPage"));

const ToTop = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ToTop />
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="products/:id" element={<ProductItemPage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="news" element={<NewsPage />} />
          <Route path="news/:id" element={<NewsItemPage />} />
          <Route path="contacts" element={<ContactsPage />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
