import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import AppRouter from "./router";

function App() {
  return (
    <div className="App">
      <AppRouter />
      <ScrollToTop />
      <ToastContainer />
    </div>
  );
}

export default App;
