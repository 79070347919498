import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Arrow from "../../assets/img/arrow.png";
import Call from "../../assets/img/contacts/call.svg";
import Global from "../../assets/img/navbar/global.svg";
import Logo from "../../assets/img/navbar/navbar-logo-desctop.svg";
import MobileLogo from "../../assets/img/navbar/navbar-logo-mobile.svg";
import HamburgerIcon from "../hamburger-icon/HamburgerIcon";
import "./navbar.scss";
import { get } from "lodash";

const LANGUAGES = [
  {
    code: "ru",
    label: "RU",
  },
  {
    code: "uz",
    label: "UZ",
  },
  {
    code: "en",
    label: "EN",
  },
];

const Navbar = ({ navNumbers }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [menu_show, setMenuShow] = useState(false);

  const toggleMenu = () => setMenuShow(!menu_show);

  const menus = [
    {
      name: t("Products"),
      target: "/products",
    },
    {
      name: t("Services"),
      target: "/services",
    },
    {
      name: t("News"),
      target: "/news",
    },
    {
      name: t("Contacts"),
      target: "/contacts",
    },
  ];

  const [lang_open, setLangOpen] = useState(false);
  const [phone_open, setPhoneOpen] = useState(false);

  const { i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState(
    JSON.parse(localStorage.getItem("updive-lang")) || "en"
  );

  const changeLanguage = (lang = "en") => {
    setCurrentLang(lang);
    setLangOpen(false);
    localStorage.setItem("updive-lang", JSON.stringify(lang));
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  const ref = useRef(null);
  const refPhone = useRef(null);

  const handleClickOutside = (event) => {
    if (lang_open === true) {
      if (ref.current && !ref.current.contains(event.target)) {
        setLangOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleClickOutsidePhone = (event) => {
    if (phone_open === true) {
      if (ref.current && !ref.current.contains(event.target)) {
        setPhoneOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePhone, true);
    return () => {
      document.removeEventListener("click", handleClickOutsidePhone, true);
    };
  });

  const setLangOpenFunc = () => {
    setLangOpen(!lang_open);
  };

  const setPhoneOpenFunc = () => {
    setPhoneOpen(!phone_open);
  };

  return (
    <nav className="Navbar">
      <div className="desctop-navbar">
        <div className="content">
          <div className="phone-box">
            <div className="phone" onClick={() => setPhoneOpenFunc()}>
              <img src={Call} alt="" />
              <span className="text">{get(navNumbers[0], "number", "")}</span>
              <img
                className={`${phone_open === true ? "open" : "close"}`}
                src={Arrow}
                alt=""
              />
            </div>
            <div
              ref={refPhone}
              className={`phone-select ${
                phone_open === true ? "open-lang" : "close-lang"
              }`}
            >
              {navNumbers.map(({ number, name }) => (
                <a href={`tel:${number}`} key={number} className="tel">
                  <span>{name}: </span>
                  {number}
                </a>
              ))}
            </div>
          </div>
          <ul>
            <li>
              <NavLink to="/products">{t("Products")}</NavLink>
            </li>

            <li>
              <NavLink to="/services">{t("Services")}</NavLink>
            </li>

            <li>
              <img
                onClick={() => {
                  scroll.scrollToTop();
                  navigate("/");
                }}
                className="desctop-logo"
                src={Logo}
                alt=""
              />
            </li>

            <li>
              <NavLink to="/news">{t("News")}</NavLink>
            </li>

            <li>
              <NavLink to="/contacts">{t("Contacts")}</NavLink>
            </li>
          </ul>
          <div className="right-box">
            <div className="language-box">
              <div
                className="selected-language"
                onClick={() => setLangOpenFunc()}
              >
                <img src={Global} alt="" className="flag-image" />
                <div className="text">{currentLang}</div>
                <img
                  className={`${lang_open === true ? "open" : "close"}`}
                  src={Arrow}
                  alt=""
                />
              </div>
              <div
                ref={ref}
                className={`select ${
                  lang_open === true ? "open-lang" : "close-lang"
                }`}
              >
                {LANGUAGES.filter((item) => item.code !== currentLang).map(
                  ({ label, code }) => (
                    <div
                      key={label}
                      className="language"
                      onClick={() => changeLanguage(code)}
                    >
                      {label}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-navbar">
        <Container>
          <div className="content-box">
            <div className="logo-box">
              <img
                className="mobile-logo"
                onClick={() => scroll.scrollToTop()}
                src={MobileLogo}
                alt=""
              />
            </div>
            <div className="right">
              <div className="btn button" onClick={() => navigate("/contacts")}>
                {t("Contact")}
              </div>
              <div className="hamburger-icon" onClick={toggleMenu}>
                <HamburgerIcon toggleMenu={toggleMenu} menu_show={menu_show} />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className={`left-menu  ${menu_show ? "open" : "close"}`}>
        <ul>
          {menus.map((menu, index) => (
            <li key={index}>
              <NavLink to={`${menu.target}`} onClick={() => toggleMenu()}>
                {menu.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="languages">
          {LANGUAGES.map(({ label, code }) => (
            <div
              key={label}
              className={`language ${
                code === currentLang ? "current-lang" : ""
              }`}
              onClick={() => changeLanguage(code)}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
