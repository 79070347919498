import axios from "axios";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Loading from "../../components/loading/Loading";
import SectionTitle from "../../components/section-title/SectionTitle";
import { API_URL } from "../../utils/constant";
import "./clients.scss";
import { get } from "lodash";

const Clients = () => {
  const { t, i18n } = useTranslation();

  const { data, isLoading } = useQuery("cliens", async () => {
    const response = await axios.get(
      `${API_URL}/api/partners?populate=*&locale=${i18n.language}`
    );
    return response.data.data;
  });

  // if (isLoading) return <Loading />;

  return (
    <div className="clients" style={{ overflow: "hidden" }}>
      <SectionTitle title={t("Our clients")} />
      <Container>
        <Row className="content">
          <Col md={12}>
            <div className="clients-container">
              {data?.map((item, index) => (
                <div className="client" key={index}>
                  <img
                    src={`${API_URL}${
                      get(item.attributes, "image", {})?.data?.attributes?.url
                    }`}
                    alt="Logo"
                  />
                  <div className="title">
                    {get(item.attributes, "name", "")}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Clients;
