import axios from "axios";
import { get } from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import SectionTitle from "../../components/section-title/SectionTitle";
import { API_URL } from "../../utils/constant";
import "./projects.scss";

const Projects = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const { data } = useQuery("projects", async () => {
    const response = await axios.get(
      `${API_URL}/api/projects?populate=*&locale=${i18n.language}`
    );
    return response.data.data;
  });

  return (
    <Element
      name="projects"
      className="projects"
      style={{ overflow: "hidden" }}
    >
      <SectionTitle title={t("Products")} />
      <Container>
        <Row className="content">
          <Col md={12}>
            <Row className="cards">
              {data?.map((item, index) => (
                <Col md={4} key={index}>
                  <div className="project-card">
                    <div className="header-card">
                      <img
                        src={`${API_URL}${
                          get(item.attributes, "logo", {})?.data?.attributes
                            ?.url
                        }`}
                        alt="Logo"
                      />
                    </div>
                    <div className="body-card">
                      <div className="card-title">
                        {get(item.attributes, "name", "")}
                      </div>
                      <div className="card-description">
                        {get(item.attributes, "subtitle", "")}
                      </div>
                      <div className="buttons">
                        <button className="left-btn">{t("Get Demo")}</button>
                        <button
                          className="right-btn"
                          onClick={() => navigate(`/products/${item.id}`)}
                        >
                          {t("Details")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Element>
  );
};

export default Projects;
