import axios from "axios";
import { get } from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import Logo from "../../assets/img/footer-logo.svg";
import { API_URL } from "../../utils/constant";
import "./footer.scss";

const Footer = () => {
  const { i18n } = useTranslation();

  const navigate = useNavigate();

  const { data } = useQuery("footer", async () => {
    const response = await axios.get(
      `${API_URL}/api/homepage?populate=contacts.icon&populate=footer&locale=${i18n.language}`
    );
    return response.data.data.attributes;
  });

  return (
    <>
      <Element name="footer" className="footer" style={{ overflow: "hidden" }}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="top">
                <img
                  src={Logo}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                />
                <div className="title">{get(data, "footer", {})?.title}</div>
                <div className="commit">
                  {get(data, "footer", {})?.description}
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="footer-contacts">
                {["phone", "instagram", "telegram", "facebook"].map(
                  (item, index) => {
                    const filteredContacts = get(data, "contacts", []).filter(
                      (contact) => contact.type === item
                    );

                    return (
                      <div key={index}>
                        {filteredContacts.map((contact, contactIndex) => (
                          <div className="contact" key={contactIndex}>
                            <a
                              href={
                                contact.type === "phone"
                                  ? `tel:${contact.url}`
                                  : contact.url
                              }
                            >
                              <img
                                src={`${API_URL}${get(
                                  contact,
                                  "icon.data.attributes.url",
                                  ""
                                )}`}
                                alt=""
                              />
                              <span>{contact.name}</span>
                            </a>
                          </div>
                        ))}
                      </div>
                    );
                  }
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Element>
    </>
  );
};

export default Footer;
