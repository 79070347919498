import React from "react";
import { Container } from "react-bootstrap";
import "./section-title.scss";

const SectionTitle = ({ title }) => {
  return (
    <div className="section-title">
      <Container>
        <div className="title-content">
          <div className="dot"></div>
          <div className="text">{title}</div>
        </div>
      </Container>
    </div>
  );
};

export default SectionTitle;
