import React from "react";
import "./loading.scss";
import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <ReactLoading
      className="Loading"
      type={"spinningBubbles"}
      color={"#71CD4D"}
      height={"100vh"}
      width={"100vw"}
    />
  );
};

export default Loading;
