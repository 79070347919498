import React, { useEffect, useState } from "react";
import Loading from "../components/loading/Loading";
import Clients from "../sections/clients/Clients";
import Footer from "../sections/footer/Footer";
import Header from "../sections/header/Header";
import Projects from "../sections/projects/Projects";
import Achievements from "../sections/achievements/Achievements";

const HomePage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 s

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Header page="home" />
      <Projects />
      <Clients />
      <Achievements />
      <Footer />
    </div>
  );
};

export default HomePage;
